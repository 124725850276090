import { template as template_2586f6f93eb340d3b3d49830e009930b } from "@ember/template-compiler";
const FKControlMenuContainer = template_2586f6f93eb340d3b3d49830e009930b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
