import { template as template_6772a5fb6d954af3b3a52b3100094f69 } from "@ember/template-compiler";
const FKLabel = template_6772a5fb6d954af3b3a52b3100094f69(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
